
import LazyHydrate from 'vue-lazy-hydration'

export default {
  name: 'Main',
  components: {
    LazyHydrate,
    RPHTabBar: () => import('rph/molecules/RPHTabBar/index.vue'),
    RPHToastContainer: () => import('rph/organisms/RPHToastContainer/index.vue'),
    RPHHeader: () => import('rph/templates/RPHHeader/index.vue'),
    RPHHeaderMobile: () => import('rph/templates/RPHHeaderMobile/index.vue'),
    RPHFooter: () => import('rph/templates/RPHFooter/index.vue'),
    RPHModalContainer: () => import('rph/templates/RPHModalContainer/index.vue'),
    RPHAppReminder: () => import('rph/organisms/RPHAppReminder/index.vue'),
    SigninModal: () => import('~/components/organisms/signin/SigninModal.vue'),
  },
  async fetch() {
    if (!this.$store.getters['rph-categories/categories'].length)
      await this.$store.dispatch('rph-categories/getCategories', { expand: 'child' })
    if (this.$route.query.formobile && this.$route.query.formobile === 'true') {
      this.$store.commit('rph-main/setIsMobileApp', true)
    }
  },

  computed: {
    /* Показывать блок поиска или нет */
    isMainPage() {
      return !(this.$route.fullPath === '/' || !!this.$route.query.pharmacy_id)
    },
    isMobileApp() {
      return this.$store.getters['rph-main/isMobileApp']
    }
  },
}
