
import LazyHydrate from 'vue-lazy-hydration'

export default {
  components: {
    LazyHydrate,
    RPHFooter: () => import('rph/templates/RPHFooter/index.vue'),
    RPHHeader: () => import('rph/templates/RPHHeader/index.vue'),
    RPHHeaderMobile: () => import('rph/templates/RPHHeaderMobile/index.vue'),
    RPHTabBar: () => import('rph/molecules/RPHTabBar/index.vue'),
    RPHAppReminder: () => import('rph/organisms/RPHAppReminder/index.vue'),
    RPHModalContainer: () => import('rph/templates/RPHModalContainer/index.vue'),
    RPHToastContainer: () => import('rph/organisms/RPHToastContainer/index.vue'),
    SigninModal: () => import('~/components/organisms/signin/SigninModal.vue'),
  },
  data() {
    return {
      activeHelpModal: false,
      onScroll: '',
    }
  },
  async fetch() {
    if (!this.$store.getters['rph-categories/categories'].length)
      await this.$store.dispatch('rph-categories/getCategories', { expand: 'child' })
    if (this.$route.query.formobile && this.$route.query.formobile === 'true') {
      this.$store.commit('rph-main/setIsMobileApp', true)
    }
  },

  computed: {
    isMobileApp() {
      return this.$store.getters['rph-main/isMobileApp']
    }
  },

  methods: {
    onHelpClick() {
      this.activeHelpModal = true
      document.querySelector('html').style.overflowY = 'hidden'
    },
    onCloseModal() {
      this.activeHelpModal = false
      document.querySelector('html').style.overflowY = 'scroll'
    },
  },
}
