
// @ts-nocheck
import Vue from 'vue'
import { mapActions, mapGetters, mapMutations } from 'vuex'
import Loupe from 'icons/loupe.svg?inline'
import BulletList from 'icons/bullet-list.svg?inline'
import Close from 'icons/close-common.svg?inline'
export default Vue.extend({
  name: 'RPHSearchBar',
  components: {
    Loupe,
    BulletList,
    Close,
    RPHSearchResult: () => import('rph/molecules/RPHSearchResult/index.vue'),
    RPHCatalogList: () => import('rph/molecules/RPHCatalogList/index.vue'),
    RPHInputSearch: () => import('rph/atoms/RPHInputSearch/index.vue'),
  },
  props: {
    /**
     * Type of the search bar
     * @values small, large
     */
    isPharmacy: {
      type: Boolean,
      default: false,
    },
    type: {
      type: String,
      default: 'small',
    },
    /**
     * show enter button
     */
    button: {
      type: Boolean,
      default: false,
    },
    collapse: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      catalogIsOpen: false,
      value: '',
      loading: false,
      categoryId: '',
      isResultsOpen: false,
      topPositionOfSearch: 0 as any,
      debouncedSearch: null as any,
    }
  },
  computed: {
    ...mapGetters({
      foundProducts: 'rph-products/foundProducts',
      popularProducts: 'rph-products/popularProducts',
    }),

    searchProducts() {
      if (this.foundProducts.length) {
        return { items: this.foundProducts, key: 'search' }
      } else {
        return { items: this.popularProducts, key: 'popular' }
      }
    },
  },
  watch: {
    value: {
      handler(data) {
        if (data) {
          this.loading = true
          this.debouncedSearch()
        } else {
          this.clearFoundProducts()
        }
      },
    },

    collapse() {
      this.clearSearch()
    },
    $route() {
      this.value = ''
    },
    async filterByCategoryId(val) {
      await this.$router.push({
        query: { ...this.$route.query, category: val },
      })
    },
  },
  beforeMount() {
    this.debouncedSearch = this.$debounce(this.findProductHandler, 500)
  },
  mounted() {
    // window.addEventListener('scroll', this.onScrollHandler.bind(this))
    const searchBar = this.$refs.searchBar as HTMLElement
    if (searchBar) {
      this.topPositionOfSearch = searchBar.getBoundingClientRect()
    }
  },
  beforeDestroy() {
    window.removeEventListener('scroll', this.onScrollHandler.bind(this))
  },
  methods: {
    ...mapActions({
      findProduct: 'rph-products/getProductsByQuery',
    }),
    ...mapMutations({
      clearFoundProducts: 'rph-products/clearFoundProducts',
    }),
    catalogHandler() {
      this.catalogIsOpen = !this.catalogIsOpen
    },
    clickOutside() {
      if (!this.loading) {
        this.catalogIsOpen = false
        this.isResultsOpen = false
        this.value = ''
      }
    },
    clearSearch() {
      this.value = ''
      this.clearFoundProducts()
    },
    closeMobileSearch() {
      this.isResultsOpen = false
    },
    searchProduct() {
      this.clearFoundProducts()
      if (this.$route.path !== '/search')
        this.$router.push({
          path: '/search',
          query: { search: this.value, page: '1' },
        })
      else {
        this.$router.push({ query: { search: this.value, page: '1' } })
      }
    },
    findProductHandler: async function (this: any) {
      try {
        await this.findProduct({
          params: {
            expand: 'annotation,prices,images,category,seo,specification,similarCount,producers',
            'filter[title]': this.value,
            'filter[pharmacy_id]': this.$route.query.pharmacy_id,
          },
        })
        this.isResultsOpen = true
      } finally {
        this.loading = false
      }
    },
    filterByCategoryId(val: any) {
      this.$router.push({
        query: { ...this.$route.query, categories: val },
      })
    },
    closeCatalog() {
      this.catalogIsOpen = false
      this.isResultsOpen = false
    },
    onScrollHandler() {
      const scrollThreshold = 300
      const elementTop = this.topPositionOfSearch.top

      if (window.scrollY > elementTop + scrollThreshold) {
        if (!this.loading) {
          this.isResultsOpen = false
        }
      }
    },
    openResultModal() {
      this.isResultsOpen = true
    },
    updateValue(newVal: string) {
      this.value = newVal
    },
  },
})
